<div class="blue-background"></div>
<div class="main-container">
  <div class="header-container">
    <div class="logo" [ngStyle]="{'background-image': backgroundImage}" (click)="handleLogoClick()"></div>
    <chat-profile-box class="col profile-box"></chat-profile-box>
  </div>
  <div class="content-container">
    <app-chat-box 
      class="col app-chat-box"
      [ngStyle]="{'display': isConversationStarted ? 'none' : 'initial'}"
      [isConversation]="false"
    ></app-chat-box>
  </div>
  <app-chat [messages]="messages"></app-chat>
</div>

<div class="fixed-bottom-bar" [ngStyle]="{'display': isConversationStarted ? 'flex' : 'none'}">
  <app-chat-box 
    class="col app-chat-box-2"
    [isConversation]="true"
  ></app-chat-box>
</div>