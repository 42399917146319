import { Component, OnInit, OnDestroy, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ConfigService } from '../config.service';
import { SearchService } from '../search.service';
import { SwirlService } from '../swirl.service'
import { LocalStorageService } from '../local-storage.service'
import { ChatService } from '../chat.service'
import { NgcCookieConsentService, NgcNoCookieLawEvent, NgcStatusChangeEvent, NgcInitializationErrorEvent, NgcInitializingEvent } from 'ngx-cookieconsent';
import { CookieService } from 'ngx-cookie-service';
import { Subscription } from 'rxjs';
@Component({
  selector: 'app-chat-spyglass',
  templateUrl: './spyglass-chat.component.html',
  styleUrls: ['./spyglass-chat.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class SpyglassChatComponent implements OnInit, OnDestroy {

  hasFacets: boolean = false;
  type: string = '';
  mode: string = localStorage.getItem('isDark') || 'Light';
  backgroundImage: string = 'url(/api/swirl/sapi/branding/?target=' + this.mode.toLowerCase() + ')';
  isLoading: boolean = false;
  sources = [
    {value: 'default', name: 'All sources', connector: 'default'}
  ];
  isEnabledCharts: boolean = true;
  isConversationStarted: boolean = false;
  messages: any[] = [];
  chatId: any = null;

   //keep refs to subscriptions to be able to unsubscribe later
   private popupOpenSubscription!: Subscription;
   private popupCloseSubscription!: Subscription;
   private initializingSubscription!: Subscription;
   private initializedSubscription!: Subscription;
   private initializationErrorSubscription!: Subscription;
   private statusChangeSubscription!: Subscription;
   private revokeChoiceSubscription!: Subscription;
   private noCookieLawSubscription!: Subscription;


  constructor(private route: ActivatedRoute, private localStorageService: LocalStorageService, private ccService: NgcCookieConsentService, private cookieService: CookieService,  private chatService: ChatService, private router: Router) {
    this.isEnabledCharts = true;
    this.isConversationStarted = false;
  }

  private updateUrlWithChatId(chatId: string): void {
    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: {
        chat_id: chatId
      },
      queryParamsHandling: 'merge', // merge with other query params
    });
  }

  initCookiesSubscriptions(): void {
    this.popupOpenSubscription = this.ccService.popupOpen$.subscribe(() => {
      console.log("Cookie consent popup opened.");
    });
  
    this.popupCloseSubscription = this.ccService.popupClose$.subscribe(() => {
      console.log("Cookie consent popup closed.");
    });
  
    this.initializingSubscription = this.ccService.initializing$.subscribe((event: NgcInitializingEvent) => {
      console.log(`Cookie consent is initializing: ${JSON.stringify(event)}`);
    });
    
    this.initializedSubscription = this.ccService.initialized$.subscribe((event) => {
      console.log(`Cookie consent has been successfully initialized: ${JSON.stringify(event)}`);
      // Now safe to check if user has responded to cookie consent
      if (this.ccService.hasAnswered()) {
        console.log(`User has already responded to cookie consent: ${this.ccService.getStatus()}`);
      }
    });
  
    this.initializationErrorSubscription = this.ccService.initializationError$.subscribe((event: NgcInitializationErrorEvent) => {
      console.error(`Failed to initialize cookie consent: ${event.error?.message}`);
    });
  
    this.statusChangeSubscription = this.ccService.statusChange$.subscribe((event: NgcStatusChangeEvent) => {
      console.log(`Cookie consent status changed to: ${event.status}`);
      // Implement logic based on consent status, e.g., enable or disable analytics cookies
      this.handleConsentChange(event.status);
    });
  
    this.revokeChoiceSubscription = this.ccService.revokeChoice$.subscribe(() => {
      console.log("User has revoked their cookie choice.");
      // Reset cookie settings or re-prompt consent if necessary
    });
  
    this.noCookieLawSubscription = this.ccService.noCookieLaw$.subscribe((event: NgcNoCookieLawEvent) => {
      console.log(`No cookie law applies: ${JSON.stringify(event)}`);
      // The law of cookies does not apply (perhaps user is from a country not requiring this)
    });
  }

  handleConsentChange(status: string): void {
    // Example of handling different statuses
    switch (status) {
      case 'allow':
        console.log("User consented to all cookies.");
        document.cookie = 'cookies_info_swirl_visited=true; path=/; max-age=' + (365 * 24 * 60 * 60);
        // Code to enable all cookies
        break;
      case 'deny':
        console.log("User denied cookies.");
        // Code to disable non-essential cookies
        break;
      case 'dismiss':
        console.log("User dismissed the cookie popup without making a choice.");
        // Consider re-prompting the user or continue with minimal cookies
        break;
    }
  }

  destroyCookiesSubscriptions(): void {
    // unsubscribe to cookieconsent observables to prevent memory leaks
    this.popupOpenSubscription.unsubscribe();
    this.popupCloseSubscription.unsubscribe();
    this.initializingSubscription.unsubscribe();
    this.initializedSubscription.unsubscribe();
    this.initializationErrorSubscription.unsubscribe();
    this.statusChangeSubscription.unsubscribe();
    this.revokeChoiceSubscription.unsubscribe();
    this.noCookieLawSubscription.unsubscribe();
  }

  ngOnInit(): void {
    if(!document.body.classList.contains("dark-theme") && this.mode === 'Dark')
      document.body.classList.toggle("dark-theme")
    document.body.classList.toggle("chat")
    this.localStorageService.localStorageChanged.subscribe((value: string) => {
      this.mode = value === 'Dark' ? 'Dark' : 'Light'
      this.backgroundImage = 'url(/api/swirl/sapi/branding/?target=' + this.mode.toLowerCase() + ')'
    });
    this.initCookiesSubscriptions()
    this.initCookieConsent();

    this.route.queryParams.subscribe(params => {
      this.chatId = params['chat_id'] || null;
      
      if (this.chatId) {
        this.chatService.setChatId(this.chatId);
        this.messages = [...this.chatService.getMessages(this.chatId)]
        this.chatService.getMessagesObservable(this.chatId).subscribe(messages => {
          this.isConversationStarted = true;
          if(messages.length == 0 && this.messages.length == 0)
            this.messages = [...this.chatService.getMessages(this.chatId)]
          if(this.messages.length < messages.length)
            this.messages = [...messages];
        });
        this.updateUrlWithChatId(this.chatId); // Update URL with chat_id
      }
    });

    this.chatService.isConversationStarted$.subscribe((isConversationStarted) => {
      this.isConversationStarted = isConversationStarted;
      this.chatId = this.chatService.getChatId()
      this.chatService.getMessagesObservable(this.chatId).subscribe(messages => {
        if(messages.length == 0 && this.messages.length == 0)
          this.messages = [...this.chatService.getMessages(this.chatId)]
        if(this.messages.length < messages.length)
          this.messages = [...messages];
      });
      this.updateUrlWithChatId(this.chatId); // Update URL with chat_id
    })

    this.chatService.eraseEvent.subscribe((isErased) => {
      if(isErased) {
        this.router.navigate(['/chat']);
        this.chatService.changeEraseEvent(false)
        this.isConversationStarted = false
        this.messages = []
        this.chatService.clearChatId()
      }
    })
  }

  
  initCookieConsent(): void {
    const consentCookie = this.cookieService.get('cookies_info_swirl_visited'); // This should match the name and criteria of your cookie setting
    if (!consentCookie) {
      this.ccService.init({
        cookie: {
          domain: window.location.hostname
        },
        autoOpen: false,
        position: "bottom",
        theme: "classic",
        palette: {
          popup: {
            background: "#0060a8",
            text: "#ffffff",
            link: "#ffffff"
          },
          button: {
            background: "#ffffff",
            text: "#000000",
            border: "transparent"
          }
        },
        type: "info",
        content: {
          message: "This website uses cookies to ensure you get the best experience on our website.",
          dismiss: "Got it!",
          deny: "Refuse cookies",
          link: "Learn more",
          href: "https://swirlaiconnect.com/privacy-policy"
        }
      });
      this.initCookiesSubscriptions();
    }
  }

  ngOnDestroy() {
    this.destroyCookiesSubscriptions()
  }

  handleLogoClick() {
    this.chatService.changeEraseEvent(true)
  }
}
