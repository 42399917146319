import { Component, OnInit, OnDestroy, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, Router, NavigationStart } from '@angular/router';
import { Location } from '@angular/common';
import { ConfigService } from '../config.service';
import { SearchService } from '../search.service';
import { SwirlService } from '../swirl.service'
import { LocalStorageService } from '../local-storage.service'
import { NgcCookieConsentService, NgcNoCookieLawEvent, NgcStatusChangeEvent, NgcInitializationErrorEvent, NgcInitializingEvent } from 'ngx-cookieconsent';
import { CookieService } from 'ngx-cookie-service';
import { Subscription } from 'rxjs';
@Component({
  selector: 'app-spyglass',
  templateUrl: './spyglass.component.html',
  styleUrls: ['./spyglass.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class SpyglassComponent implements OnInit, OnDestroy {

  hasFacets: boolean = false;
  type: string = '';
  mode: string = localStorage.getItem('isDark') || 'Light';
  backgroundImage: string = 'url(/api/swirl/sapi/branding/?target=' + this.mode.toLowerCase() + ')';
  isLoading: boolean = false;
  sources = [
    {value: 'default', name: 'All sources', connector: 'default'}
  ];
  isEnabledCharts: boolean = true;

   //keep refs to subscriptions to be able to unsubscribe later
   private popupOpenSubscription!: Subscription;
   private popupCloseSubscription!: Subscription;
   private initializingSubscription!: Subscription;
   private initializedSubscription!: Subscription;
   private initializationErrorSubscription!: Subscription;
   private statusChangeSubscription!: Subscription;
   private revokeChoiceSubscription!: Subscription;
   private noCookieLawSubscription!: Subscription;


  constructor(
    private route: ActivatedRoute, 
    private configService: ConfigService, 
    private searchService: SearchService, 
    private swirl: SwirlService, 
    private localStorageService: LocalStorageService, 
    private ccService: NgcCookieConsentService, 
    private cookieService: CookieService,
    private router: Router,
    private location: Location
  ) {
    this.isEnabledCharts = true;
  }

  initCookiesSubscriptions(): void {
    this.popupOpenSubscription = this.ccService.popupOpen$.subscribe(() => {
      console.log("Cookie consent popup opened.");
    });
  
    this.popupCloseSubscription = this.ccService.popupClose$.subscribe(() => {
      console.log("Cookie consent popup closed.");
    });
  
    this.initializingSubscription = this.ccService.initializing$.subscribe((event: NgcInitializingEvent) => {
      console.log(`Cookie consent is initializing: ${JSON.stringify(event)}`);
    });
    
    this.initializedSubscription = this.ccService.initialized$.subscribe((event) => {
      console.log(`Cookie consent has been successfully initialized: ${JSON.stringify(event)}`);
      // Now safe to check if user has responded to cookie consent
      if (this.ccService.hasAnswered()) {
        console.log(`User has already responded to cookie consent: ${JSON.stringify(this.ccService.getStatus())}`);
      }
    });
  
    this.initializationErrorSubscription = this.ccService.initializationError$.subscribe((event: NgcInitializationErrorEvent) => {
      console.error(`Failed to initialize cookie consent: ${event.error?.message}`);
    });
  
    this.statusChangeSubscription = this.ccService.statusChange$.subscribe((event: NgcStatusChangeEvent) => {
      console.log(`Cookie consent status changed to: ${event.status}`);
      // Implement logic based on consent status, e.g., enable or disable analytics cookies
      this.handleConsentChange(event.status);
    });
  
    this.revokeChoiceSubscription = this.ccService.revokeChoice$.subscribe(() => {
      console.log("User has revoked their cookie choice.");
      // Reset cookie settings or re-prompt consent if necessary
    });
  
    this.noCookieLawSubscription = this.ccService.noCookieLaw$.subscribe((event: NgcNoCookieLawEvent) => {
      console.log(`No cookie law applies: ${JSON.stringify(event)}`);
      // The law of cookies does not apply (perhaps user is from a country not requiring this)
    });
  }

  handleConsentChange(status: string): void {
    // Example of handling different statuses
    switch (status) {
      case 'allow':
        console.log("User consented to all cookies.");
        // Code to enable all cookies
        break;
      case 'deny':
        console.log("User denied cookies.");
        // Code to disable non-essential cookies
        break;
      case 'dismiss':
        // when user clicked 'Got it!'
        console.log("User dismissed the cookie popup without making a choice.");
        document.cookie = 'cookies_info_swirl_visited=true; path=/; max-age=' + (365 * 24 * 60 * 60);
        // Consider re-prompting the user or continue with minimal cookies
        break;
    }
  }

  destroyCookiesSubscriptions(): void {
    // unsubscribe to cookieconsent observables to prevent memory leaks
    this.popupOpenSubscription.unsubscribe();
    this.popupCloseSubscription.unsubscribe();
    this.initializingSubscription.unsubscribe();
    this.initializedSubscription.unsubscribe();
    this.initializationErrorSubscription.unsubscribe();
    this.statusChangeSubscription.unsubscribe();
    this.revokeChoiceSubscription.unsubscribe();
    this.noCookieLawSubscription.unsubscribe();
  }

  handleBackButton(): void {
    this.searchService.changeEraseEvent(true)
  }

  ngOnInit(): void {
    this.router.events.subscribe(event => {
      if (event instanceof NavigationStart) {
        if (event.navigationTrigger === 'popstate') {
          this.handleBackButton();
        }
      }
    });
    if(!document.body.classList.contains("dark-theme") && this.mode === 'Dark')
      document.body.classList.toggle("dark-theme")
    this.localStorageService.localStorageChanged.subscribe((value: string) => {
      this.mode = value === 'Dark' ? 'Dark' : 'Light'
      this.backgroundImage = 'url(/api/swirl/sapi/branding/?target=' + this.mode.toLowerCase() + ')'
    });
    this.swirl.getSources().subscribe(async response => {
      response.filter((source: any) => source.active).forEach((source: any) => {
        this.sources.push({
          value: source.id,
          name: source.name,
          connector: source.connector
        })
      })
      const params = this.route.snapshot.queryParamMap;
      const scope = params.get('scope') || 'default';
      this.configService.getConfig(scope).subscribe(config => {
        if(config && !this.isLoading) {
          this.hasFacets = config.facetLayout != null;
          this.type = config['type'];
        }
      });
      this.searchService.isLoading.subscribe(isLoading => this.isLoading = isLoading);
    })
    this.searchService.isEnabledCharts.subscribe(isEnabledCharts => this.isEnabledCharts = isEnabledCharts);

    this.initCookiesSubscriptions()
    this.initCookieConsent();
  }

  
  initCookieConsent(): void {
    const consentCookie = this.cookieService.get('cookies_info_swirl_visited');
    if (!consentCookie || consentCookie != 'true') {
      this.ccService.init({
        cookie: {
          domain: window.location.hostname
        },
        autoOpen: true,
        position: "bottom",
        theme: "classic",
        palette: {
          popup: {
            background: "#0060a8",
            text: "#ffffff",
            link: "#ffffff"
          },
          button: {
            background: "#ffffff",
            text: "#000000",
            border: "transparent"
          }
        },
        type: "info",
        content: {
          message: "This website uses cookies to ensure you get the best experience on our website.",
          allow: "Got it!",
          deny: "Refuse cookies",
          link: "Learn more",
          href: "https://swirlaiconnect.com/privacy-policy"
        }
      });
      this.initCookiesSubscriptions();
    }
  }

  ngOnDestroy() {
    this.destroyCookiesSubscriptions()
  }

  handleLogoClick() {
    this.searchService.changeEraseEvent(true)
  }
}
